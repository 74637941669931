.selectize-control.plugin-drag_drop.multi>.selectize-input.dragging {
    overflow: visible
}

.selectize-control.plugin-drag_drop.multi>.selectize-input>div.ui-sortable-placeholder {
    visibility: visible !important;
    background: #f2f2f2 !important;
    background: rgba(0, 0, 0, .06) !important;
    border: 0 none !important;
    box-shadow: inset 0 0 12px 4px #fff
}

.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
    content: "!";
    visibility: hidden
}

.selectize-control.plugin-drag_drop .ui-sortable-helper {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2)
}

.selectize-control .dropdown-header {
    position: relative;
    padding: 6px .75rem;
    border-bottom: 1px solid #d0d0d0;
    background: #f8f8f8;
    border-radius: .25rem .25rem 0 0
}

.selectize-control .dropdown-header-close {
    position: absolute;
    right: .75rem;
    top: 50%;
    color: #343a40;
    opacity: .4;
    margin-top: -12px;
    line-height: 20px;
    font-size: 20px !important
}

.selectize-control .dropdown-header-close:hover {
    color: #000
}

.selectize-dropdown.plugin-optgroup_columns .selectize-dropdown-content {
    display: flex
}

.selectize-dropdown.plugin-optgroup_columns .optgroup {
    border-right: 1px solid #f2f2f2;
    border-top: 0 none;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
    border-right: 0 none
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
    display: none
}

.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
    border-top: 0 none
}

.selectize-control.plugin-remove_button .item {
    display: inline-flex;
    align-items: center;
    padding-right: 0 !important
}

.selectize-control.plugin-remove_button .item .remove {
    color: inherit;
    text-decoration: none;
    vertical-align: middle;
    display: inline-block;
    padding: 1px 5px;
    border-left: 1px solid #dee2e6;
    border-radius: 0 2px 2px 0;
    box-sizing: border-box;
    margin-left: 5px
}

.selectize-control.plugin-remove_button .item .remove:hover {
    background: rgba(0, 0, 0, .05)
}

.selectize-control.plugin-remove_button .item.active .remove {
    border-left-color: rgba(0, 0, 0, 0)
}

.selectize-control.plugin-remove_button .disabled .item .remove:hover {
    background: none
}

.selectize-control.plugin-remove_button .disabled .item .remove {
    border-left-color: #fff
}

.selectize-control.plugin-remove_button .remove-single {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 23px
}

.selectize-control {
    position: relative
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
    color: $form-select-color;
    font-family: inherit;
    font-size: 0.875rem;
    line-height: 1.5;
    font-smoothing: inherit
}

.selectize-input,
.selectize-control.single .selectize-input.input-active {
    background: $form-select-bg;
    cursor: text;
    display: inline-block
}

.selectize-input {
    border: 1px solid $form-select-border-color;
    padding: .375rem .75rem;
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: .25rem
}

.selectize-control.multi .selectize-input.has-items {
    padding: calc(0.375rem - 1px - 0px) .75rem calc(0.375rem - 1px - 3px - 0px)
}

.selectize-input.full {
    background-color: $form-select-bg;
}

.selectize-input.disabled,
.selectize-input.disabled * {
    cursor: default !important
}

.selectize-input.focus {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .15)
}

.selectize-input.dropdown-active {
    border-radius: .25rem .25rem 0 0
}

.selectize-input>* {
    vertical-align: baseline;
    display: inline-block;
    zoom: 1
}

.selectize-control.multi .selectize-input>div {
    cursor: pointer;
    margin: 0 3px 3px 0;
    padding: 1px 5px;
    background: var(--bs-secondary-bg);
    color: $form-select-color;
    border: 0px solid #dee2e6
}

.selectize-control.multi .selectize-input>div.active {
    background: var(--bs-secondary-bg);
    color: $form-select-color;
    border: 0px solid rgba(0, 0, 0, 0)
}

.selectize-control.multi .selectize-input.disabled>div,
.selectize-control.multi .selectize-input.disabled>div.active {
    color: #878787;
    background: #fff;
    border: 0px solid #fff
}

.selectize-input>input {
    display: inline-block !important;
    padding: 0 !important;
    min-height: 0 !important;
    max-height: none !important;
    max-width: 100% !important;
    margin: 0 !important;
    text-indent: 0 !important;
    border: 0 none !important;
    background: none !important;
    line-height: inherit !important;
    user-select: auto !important;
    box-shadow: none !important
}

.selectize-input>input::-ms-clear {
    display: none
}

.selectize-input>input:focus {
    outline: none !important
}

.selectize-input>input[placeholder] {
    box-sizing: initial
}

.selectize-input.has-items>input {
    margin: 0 0px !important
}

.selectize-input::after {
    content: " ";
    display: block;
    clear: left
}

.selectize-input.dropdown-active::before {
    content: " ";
    display: block;
    position: absolute;
    background: #fff;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0
}

.selectize-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    border: 1px solid $form-select-border-color;
    background: $form-select-bg;
    margin: -1px 0 0 0;
    border-top: 0 none;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    border-radius: 0 0 .25rem .25rem
}

.selectize-dropdown [data-selectable] {
    cursor: pointer;
    overflow: hidden
}

.selectize-dropdown [data-selectable] .highlight {
    background: rgba(255, 237, 40, .4);
    border-radius: 1px
}

.selectize-dropdown .option,
.selectize-dropdown .optgroup-header,
.selectize-dropdown .no-results,
.selectize-dropdown .create {
    padding: 3px .75rem
}

.selectize-dropdown .option,
.selectize-dropdown [data-disabled],
.selectize-dropdown [data-disabled] [data-selectable].option {
    cursor: inherit;
    opacity: .5
}

.selectize-dropdown [data-selectable].option {
    opacity: 1;
    cursor: pointer
}

.selectize-dropdown .optgroup:first-child .optgroup-header {
    border-top: 0 none
}

.selectize-dropdown .optgroup-header {
    color: #6c757d;
    background: $form-select-bg;
    cursor: default
}

.selectize-dropdown .active {
    background-color: var(--bs-secondary-bg);
    color: var(--bs-body-color);
}

.selectize-dropdown .active.create {
    color: var(--bs-body-color);
}

.selectize-dropdown .create {
    color: rgba(52, 58, 64, .5)
}

.selectize-dropdown-content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
    overflow-scrolling: touch;
    div {
        padding: 2px 5px;
    }
}

.selectize-dropdown-emptyoptionlabel {
    text-align: center
}

.selectize-dropdown .spinner {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 3px .75rem
}

.selectize-dropdown .spinner:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 3px;
    border-radius: 50%;
    border: 5px solid #d0d0d0;
    border-color: #d0d0d0 rgba(0, 0, 0, 0) #d0d0d0 rgba(0, 0, 0, 0);
    animation: lds-dual-ring 1.2s linear infinite
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
    cursor: pointer
}

.selectize-control.single .selectize-input.input-active,
.selectize-control.single .selectize-input.input-active input {
    cursor: text
}

.selectize-control.single .selectize-input:after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    right: 0.75rem;
    margin-top: -6px;
    width: 16px;
    height: 12px;
    border: 0;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px 12px;
}

[data-bs-theme=dark] .selectize-control.single .selectize-input:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.selectize-control.single .selectize-input.dropdown-active:after {
    margin-top: -6px;

}

.selectize-control.rtl {
    text-align: right
}

.selectize-control.rtl.single .selectize-input:after {
    left: calc(0.75rem + 5px);
    right: auto
}

.selectize-control.rtl .selectize-input>input {
    margin: 0 4px 0 -2px !important
}

.selectize-control .selectize-input.disabled {
    opacity: .5;
    background-color: #fff
}

.selectize-dropdown,
.selectize-dropdown.form-control {
    height: auto;
    padding: 0;
    margin: 2px 0 0 0;
    z-index: 1000;
    background: $form-select-bg;
    border: 1px solid $form-select-border-color;
    border-radius: .25rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
}

.selectize-dropdown .optgroup-header {
    font-size: .875rem;
    line-height: 1.5
}

.selectize-dropdown .optgroup:first-child:before {
    display: none
}

.selectize-dropdown .optgroup:before {
    content: " ";
    display: block;
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid $form-select-border-color;
    margin-left: -0.75rem;
    margin-right: -0.75rem
}

.selectize-dropdown .create {
    padding-left: .75rem
}

.selectize-dropdown-content {
    padding: 5px 0
}

.selectize-dropdown-emptyoptionlabel {
    text-align: center
}

.selectize-input {
    min-height: calc(1.5em + 0.75rem + 2px);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .selectize-input {
        transition: none
    }
}

.selectize-input.dropdown-active {
    border-radius: .25rem
}

.selectize-input.dropdown-active::before {
    display: none
}

.selectize-input.focus {
    border-color: $form-select-focus-border-color;
    outline: 0;

    @if $enable-shadows {
        @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
    }

    @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $form-select-focus-box-shadow;
    }
}

.is-invalid .selectize-input {
    border-color: #dc3545;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.is-invalid .selectize-input:focus {
    border-color: #bd2130;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #eb8c95
}

.selectize-control.form-control-sm .selectize-input.has-items {
    min-height: calc(1.5em + 0.5rem + 2px) !important;
    height: calc(1.5em + 0.5rem + 2px);
    padding: .25rem .5rem !important;
    font-size: 0.875rem;
    line-height: 1.25
}

.selectize-control.multi .selectize-input.has-items {
    height: auto;
    padding-left: calc(0.75rem - 5px);
    padding-right: calc(0.75rem - 5px)
}

.selectize-control.multi .selectize-input>div {
    border-radius: calc(0.25rem - 1px)
}

.form-control.selectize-control {
    padding: 0;
    height: auto;
    border: none;
    background: none;
    box-shadow: none;
    border-radius: 0
}

.input-group .selectize-input {
    overflow: unset;
    border-radius: 0 .25rem .25rem 0
}