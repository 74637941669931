/*
 * User Switcher
 */

 .result-container {
    margin-top: 20px;
    max-height: 310px;
    overflow-y: auto;
    .result-item {
        background-color: var(--bs-tertiary-bg);
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        h4 {
            color: var(--bs-secondary-color);
            font-size: 14px;
            margin: 0;
            padding: 5px;
        }
        &.active {
            background-color: $primary;
            h4 {
                color: #ffffff;
            }
        }
    }
}


/*
 * Forms
 */

.form-label {
    color: var(--#{$prefix}body-color);
}

.form-check-label {
    color: var(--#{$prefix}body-color);
    font-weight: 500;
}

.form-check-inline {
    background-color: #f5f7f9;
    border-radius: 6px;
    padding: 0.5rem 1rem 0.5rem 2rem;
    .form-check-label {
        font-weight: 400;
    }
}

.form-check {
    font-size: 0.875rem;
    .form-check-label {
        font-weight: 400;
    }
}

.btn-action {
    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: var(--bs-tertiary-bg);
    --bs-btn-border-color: var(--bs-tertiary-bg);
    --bs-btn-hover-color: var(--bs-body-color);
    --bs-btn-hover-bg: var(--bs-secondary-bg);
    --bs-btn-hover-border-color: var(--bs-secondary-bg);
  }

.btn-outline-action {
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-border-color: transparent;
    border: 0;
    &.show {
        --bs-btn-bg: transparent !important;
        --bs-btn-border-color: transparent !important;
        border: 0;
        &:focus-visible {
            box-shadow: none;
        }
    }
}

.btn-gradient {
    --bs-btn-font-weight: 400;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: #F3F5F7;
    --bs-btn-border-color: #CFD7DF;
    background-image: linear-gradient(to bottom, #FFF, #F3F5F7);
    --bs-btn-hover-color: var(--bs-body-color);
    --bs-btn-hover-bg: #f3f5f7;
    --bs-btn-hover-border-color: #CFD7DF;
    &:hover {
        background-color: #f3f5f7;
        background-image:none
    }
    &::after {
        border: 0;
        display: block;
        position: absolute;
        top: 50%;
        right: .75rem;
        margin-top: -6px;
        width: 14px;
        height: 12px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: 14px 12px;
    }
    &.dropdown-toggle {
        padding-right: 32px;
    }
    &.disabled {
        border-color: #CFD7DF;
        opacity: 0.4;
        pointer-events: none;
        cursor: none;
    }
}

.check-active {
    .dropdown-item {
        i {
            color: $primary;
            opacity: 0;
        }
        &.active {
            color: $primary;
            background-color: $white;
            i {
                opacity: 1;
            }
            
        }
    }
    
}

[data-bs-theme=dark] {
    .btn-action {
        --bs-btn-font-weight: 600;
        --bs-btn-color: var(--bs-body-color);
        --bs-btn-bg: var(--bs-tertiary-bg);
        --bs-btn-border-color: var(--bs-tertiary-bg);
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-hover-bg: var(--bs-secondary-bg);
        --bs-btn-hover-border-color: var(--bs-secondary-bg);
    }

    .btn-outline-action {
        --bs-btn-bg: transparent;
        --bs-btn-border-color: transparent;
    }
}

.alert {
    .errorlist {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }
}

.messages {
    .success {
        background-color: $success;
        color: $white;
    }
}

.table th {
    font-size: 11px;
    word-wrap: none;
    text-overflow: ellipsis;
}


.filter {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid $gray-500;
    border-radius: 6px;
    font-size: 0.75rem;
    padding: 6px 12px;

    i {
        color: $gray-500;
    }
}

.form-label,
.form-check-label {
    .fa-asterisk {
        font-size: 0.5rem;
        color: $danger;
        vertical-align: 4px;
    }
}

.tab-section {
    position: sticky;
    top: 102px;
    z-index: 80;
    background-color: #f5f7f9;
    border-bottom: 1px #cfd7df solid;
    box-shadow: 0 2px 4px 0 rgba(24,50,71,.08);
    width:calc(100% - 340px);
}

.form-section {
    margin-bottom: 1rem;
    background-color: $white;
    box-shadow: 0 1px 0 0 #cfd7df;
    padding: 2rem 2rem 2rem calc(2rem - 2px);
    small {
        display: block;
        margin-bottom: 1rem;
    }
}

.form-section.invalid {
    h5 {
        color: $danger;
    }
    border-left: 2px solid $danger;
}

.no-results {
    min-height: calc(100vh - 300px);
    i {
        color: var(--bs-body-color);
        font-size: 80px;
    }
}

/*
 * Auto grow textareas
 */
.grow-wrap {
    display: grid;
}

.grow-wrap::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
}

.grow-wrap>textarea {
    resize: none;
    overflow: hidden;
}

.grow-wrap>textarea,
.grow-wrap::after {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
    grid-area: 1 / 1 / 2 / 2;
}

.grow-wrap::after {
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}

tr[data-row-url] td:not(.no-row-click) {
    cursor: pointer;
}

/*
 * Form Submission
 */
 .form-container {
    max-width: 1200px;
    //margin-top: 1rem;
    margin-bottom: 5rem;
    &.full-width {
        max-width: 100%;
    }
}

.list-container {
    width: 100%;
    table {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
        box-sizing: border-box;
        //max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }
}

.side-panel-open .form-container,
.side-panel-open .list-container {
   width:calc(100% - 340px);
}

#list-search-filters-button {
    svg {
        width: 16px;
        height: 16px;
        transform: scaleX(-1);
        transition: all 0.2s ease-in-out;
        vertical-align: -0.2rem;
    }
}

.side-panel-open {
    #list-search-filters-button {
        border: 1px solid #b1bdc8;
        box-shadow: inset 0 0 4px 0 rgba(39,49,58,.26);
        svg {
            transform: scaleX(1);
        }
    }
}

.form-footer {
    box-sizing: border-box;
    display: block;
    position: fixed;
    bottom: 0;
    margin: 0 12px;
    width: auto;
    z-index: 20;

    ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            float: left;
            list-style: none;
        }
    }

    .footer-container {
        background-color: var(--bs-secondary-bg);
        border-radius: 6px 6px 0 0;
        padding: 0.75rem;

        p {
            margin: 0 50px 0 0;
            padding: 0;
            color: $white;
            font-size: 0.75rem;
        }
    }

    .footer-container:not(:first-child) {
        margin-left: -6px;
        border-top-left-radius: 0;
    }   
    
    .actions {
        width: 250px;
        &.actions--primary {
            width: 350px;
        }
        .form-dropdown {
            position: relative;
            &:after, 
            &:before {
                content: " ";
                display: table;
            }
            &:after {
                clear:both;
            }
            .button {
                align-items: center;
                border-radius: 6px;
                background-color: $primary;
                border: 0;
                color: $white;
                display: inline-flex;
                float: left;
                height: 3em;
                line-height: 1em;
                padding: 0 5em 0 1em;
                text-align: left;
                width: 100%;
                font-weight: 600;
                text-overflow: ellipsis;
                overflow: hidden;
                position: relative;
                text-decoration: none;
                transition: background-color .1s ease;
                vertical-align: middle;
                white-space: nowrap;
                &:disabled,
                &[disabled] {
                    background-color: darken($primary, 20%);
                }
                &:hover {
                    background-color: darken($primary, 10%);
                }
                i,
                .spinner-grow {
                    margin-right: 0.5rem;
                }
            }
            .form-dropdown-toggle {
                background-color: $primary;
                border-left: 1px solid darken($primary, 20%);
                border-radius: 0 6px 6px 0;
                color: $white;
                cursor: pointer;
                height: 100%;
                line-height: 3em;
                padding: 0 1em;
                position: absolute;
                right: 0;
                text-align: center;
                &:hover {
                    background-color: darken($primary, 10%);
                }
            }
            ul {
                min-width: 110px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                width: 100%;
                z-index: 500;
                li {
                    float: none;
                    overflow: hidden;
                    white-space: nowrap;
                    border-bottom: 1px solid var(--bs-body-bg);
                }
            }
            &.open {
                .button {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
                ul {
                    display: block;
                    opacity: 1;
                    .button {
                        border-radius: 0;
                        background-color: var(--bs-tertiary-bg);
                        &:hover {
                            background-color: var(--bs-secondary-bg);
                        }
                    }
                    li:first-child {
                        .button {
                            border-top-left-radius: 6px;
                            border-top-right-radius: 6px;
                        }
                    }
                }
                .form-dropdown-toggle {
                    border-top-right-radius: 0;
                    background-color: darken($primary, 10%);
                }
            }
            &.dropup {
                ul {
                    bottom: 100%;
                    top: auto;
                }
            }
        }
        
    }
}

[data-bs-theme=dark] .form-footer .actions .form-drop-down .button {
    color: var(--bs-body-bg);
}

/*
 * Form sidebars
 */
.form-side {
    background-color: #f5f7f9;
    border-left: 1px solid #cfd7df;
    height: calc(100vh - 100%);
    font-size: 0.875em;
    right: 0;
    padding: 10px;
    position: absolute;
    top: 100%;
    transform: translate(100%,0);
    transition-duration: .3s;
    
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    width: 340px;
    z-index: 90;
    &__fixed {
        position: absolute;
        background-color: #f5f7f9;
        border-top: 1px #ebeff3 solid;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1rem 10px;
        width: 100%;
    }
}

.has-messages {
    .form-side {
        height: calc(100vh - 100% - 67px);
    }
}

@media (min-width: 50em) {
    .form-side {
        max-width: 340px;
    }
}

.form-side--open {
    transform: translate(0,0)
}

.form-side--open .form-side__panel {
    display: block
}

.form-side__panel {
    display: none;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.form-side__inner {
    min-height: 100%;
    padding: 1rem;
}

.form-side__section {
    margin-bottom: 3rem;
}

.form-side__heading {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.drag {
    cursor:move;
}

.modal-title h5 {
    margin-bottom: 0;
}

/*
 * Colour Picker
 */
 .clr-field {
    display: block !important;
    button {
        border-radius: 6px !important;
    }
}

/*
 * Listing Portals
 */
 .portal-card-area{position: relative;}
 .single-portal-card{
     border:1px solid var(--bs-secondary-bg);
     background-color: var(--bs-tertiary-bg);
     overflow: hidden;
     transition: all 0.3s linear;
 }
 .portal-card-title{
     padding: 10px;
     text-align: center;
     background-color: var(--bs-secondary-bg);
 }
 .portal-card-title h3{
     font-size: 14px;
     font-weight: 400;
     color: var(--bs-body-color);
     margin-bottom: 0;
     transition: all 0.3s linear;
 }

 .portal-card-icon{
     max-width: 100%;
     min-height: 135px;
     text-align: center;
     display: flex;
     align-items: center;
     justify-content: center;
 }



 /*
    Freshdesk Widget Overrides
*/
#freshworks-container {
    #launcher-frame {
        min-width: 95px !important;
        max-width: 95px !important;
    }
}