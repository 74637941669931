#stream-container {
    margin-top: 1rem;
    text-align: center;
}

.timeline-header {
    white-space: nowrap;
    margin: 0 1rem 8px 1rem;
    font-size: 15px;
    text-transform: uppercase;
    border-radius: 2px;
    background-color: #F3F5F7;
    background-image: linear-gradient(to bottom, #FFF, #F3F5F7);
    box-shadow: 0 1px 0 0 #cfd7df;
    padding: 1rem;
    text-align: center;
}

.timeline {
    position: relative;
    padding: 0 1rem 0 1rem;
    text-align: left;

    .timeline-container {
        position: relative;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 1px 0 0 #cfd7df;
        display: table;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 8px;
        min-height: 100px;

        .timeline-icon {
            position: absolute;
            left: 20px;
            top: 20px;
            width: 60px;
            height: 60px;
            border-radius: 4px;
            text-align: center;
            font-size: 1.4rem;
            background: $secondary;

            i {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: $white;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .timeline-body {
            position: relative;
            padding: 20px 15px 20px 100px;
            

            .timeline-title {
                margin-bottom: 0.25rem;
                font-size: 14px;
                a {
                    font-weight: 500;
                    color: var(--bs-body-color);
                    
                }
            }

            .timeline-subtitle {
                font-size: 13px;
            }

            .table th,
            .table td {
                font-size: 12px;
            }
            .timeline-toggle {
                color: var(--bs-body-color);
            }
        }

        // Primary Timeline
        &.primary {
            .badge,
            .timeline-icon {
                background: $primary !important;
            }
        }

        // Info Timeline
        &.info {
            .badge,
            .timeline-icon {
                background: $info !important;
            }
        }

        // Success Timeline
        &.success {
            .badge,
            .timeline-icon {
                background: $success !important;
            }
        }

        // Warning Timeline
        &.warning {
            .badge,
            .timeline-icon {
                background: $warning !important;
            }
        }

        // Danger Timeline
        &.danger {
            .badge,
            .timeline-icon {
                background: $danger !important;
            }
            .timeline-body {
                border-color: $danger !important;
            }
        }

    }
}