//---------------------------------------------
// Menu
//---------------------------------------------
//$menu-bg: #2D3A3A;
//$menu-item-bg: #242E2E;
$menu-bg: var(--bs-tertiary-bg);
$menu-item-bg: var(--bs-secondary-bg);
$menu-item: var(--bs-body-color);
$menu-item-hover: $primary;
$menu-item-active: $primary;


.left-menu {
    width: 200px;
    z-index: 10;
    background: $menu-bg;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: 100px;

    a {
        text-decoration: none;
    }
}

.side-nav {
    padding-left: 0;
    list-style-type: none
}

.side-nav ul {
    list-style-type: none
}

.side-nav .side-nav-link {
    color: $menu-item;
    display: block;
    padding: 10px 20px;
    position: relative;
    -webkit-transition: all .4s;
    transition: all .4s;
    list-style: none
}

.side-nav .side-nav-link:active,
.side-nav .side-nav-link:focus,
.side-nav .side-nav-link:hover {
    color: $menu-item-hover;
    text-decoration: none
}

.side-nav .side-nav-link span {
    vertical-align: middle
}

.side-nav .side-nav-link i {
    display: inline-block;
    line-height: 1.0625rem;
    margin: 0 5px 0 0;
    font-size: 1rem;
    vertical-align: middle;
    width: 20px
}

.side-nav .side-nav-link svg {
    display: inline-block;
    line-height: 1.0625rem;
    margin: 0 5px 0 0;
    font-size: 1rem;
    vertical-align: middle;
    width: 20px;
    path {
        width: 16px;
        height: 16px;
    }
  }

.side-nav .menu-arrow {
    -webkit-transition: -webkit-transform .15s;
    transition: -webkit-transform .15s;
    transition: transform .15s;
    transition: transform .15s,-webkit-transform .15s;
    position: absolute;
    right: 30px;
    display: inline-block;
    font-family: "bootstrap-icons";
    text-rendering: auto;
    line-height: 1.5rem;
    font-size: 0.8rem;
    -webkit-transform: translate(0,0);
    transform: translate(0,0)
}

.side-nav .menu-arrow:before {
    content: "\f285"
}

.side-nav .badge {
    margin-top: 3px
}

.side-nav .side-nav-item>a[aria-expanded=true]>span.menu-arrow {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.side-nav .side-nav-item.menuitem-active>a:not(.collapsed)>span.menu-arrow {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.side-nav .side-nav-title {
    padding: 12px 20px;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    text-transform: uppercase;
    color: $menu-item;
    font-weight: 700
}

.side-nav .menuitem-active>a {
    color: $menu-item-active!important;
}

.side-nav-item.menuitem-active>a {
    background-color: $menu-item-bg;
    i {
        color: $primary;
    }
    &:after{
        content: "";
        height: 80%;
        background: $primary;
        width: 6px;
        position: absolute;
        top: 10%;
        left: -3px;
        border-radius: 20px;
    }
}

.side-nav-item.menuitem-active ul {
    background-color: $menu-item-bg;
}

[dir=ltr] .side-nav .side-nav-item .menu-arrow:before {
    content: "\f0141"!important
}

.side-nav-forth-level,.side-nav-second-level,.side-nav-third-level {
    padding-left: 0;
    padding-bottom: 5px;
}

.side-nav-forth-level li .side-nav-link,
.side-nav-forth-level li a,
.side-nav-second-level li .side-nav-link,
.side-nav-second-level li a,
.side-nav-third-level li .side-nav-link,
.side-nav-third-level li a {
    padding: 8px 20px 8px 48px;
    color: $menu-item;
    display: block;
    position: relative;
    -webkit-transition: all .4s;
    transition: all .4s;
}

.side-nav-forth-level li .side-nav-link:focus,
.side-nav-forth-level li .side-nav-link:hover,
.side-nav-forth-level li a:focus,
.side-nav-forth-level li a:hover,.side-nav-second-level li .side-nav-link:focus,.side-nav-second-level li .side-nav-link:hover,.side-nav-second-level li a:focus,.side-nav-second-level li a:hover,.side-nav-third-level li .side-nav-link:focus,.side-nav-third-level li .side-nav-link:hover,.side-nav-third-level li a:focus,.side-nav-third-level li a:hover {
    color: $primary;
}

.side-nav-forth-level li .side-nav-link .menu-arrow,.side-nav-forth-level li a .menu-arrow,.side-nav-second-level li .side-nav-link .menu-arrow,.side-nav-second-level li a .menu-arrow,.side-nav-third-level li .side-nav-link .menu-arrow,.side-nav-third-level li a .menu-arrow {
    line-height: 1.3rem
}

.side-nav-forth-level li.active>a,.side-nav-second-level li.active>a,.side-nav-third-level li.active>a {
    color: $menu-item-active;
}

.side-nav-third-level li .side-nav-link,.side-nav-third-level li a {
    padding: 8px 30px 8px 80px
}

.side-nav-forth-level li .side-nav-link,.side-nav-forth-level li a {
    padding: 8px 30px 8px 100px
}

body[data-leftbar-compact-mode=condensed] {
    //min-height: 1600px;
    .wrapper {
        padding-left: 70px;
    }
}

body[data-leftbar-compact-mode=condensed] .wrapper .left-menu {
    position: absolute;
    padding-top: 80px;
    width: 70px;
    z-index: 5000;
}

body[data-leftbar-compact-mode=condensed] .wrapper .left-menu .simplebar-content-wrapper,
body[data-leftbar-compact-mode=condensed] .wrapper .left-menu .simplebar-mask {
    overflow: visible!important
}

body[data-leftbar-compact-mode=condensed] .wrapper .left-menu .simplebar-scrollbar {
    display: none!important
}

body[data-leftbar-compact-mode=condensed] .wrapper .left-menu .simplebar-offset {
    bottom: 0!important
}

body[data-leftbar-compact-mode=condensed] .wrapper .left-menu .logo {
    width: 70px;
    z-index: 1;
    background: $menu-bg;
}

body[data-leftbar-compact-mode=condensed] .side-nav .badge,
body[data-leftbar-compact-mode=condensed] .side-nav .menu-arrow,
body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-title {
    display: none!important
}

body[data-leftbar-compact-mode=condensed] .side-nav .collapse,
body[data-leftbar-compact-mode=condensed] .side-nav .collapsing {
    display: none;
    height: inherit!important;
    -webkit-transition: none!important;
    transition: none!important
}

body[data-leftbar-compact-mode=condensed] .side-nav .collapse .side-nav-forth-level,
body[data-leftbar-compact-mode=condensed] .side-nav .collapse .side-nav-second-level,
body[data-leftbar-compact-mode=condensed] .side-nav .collapse .side-nav-third-level,
body[data-leftbar-compact-mode=condensed] .side-nav .collapsing .side-nav-forth-level,
body[data-leftbar-compact-mode=condensed] .side-nav .collapsing .side-nav-second-level,
body[data-leftbar-compact-mode=condensed] .side-nav .collapsing .side-nav-third-level {
    display: none!important
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item {
    position: relative;
    //white-space: nowrap
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link {
    padding: 15px 20px;
    min-height: 54px;
    -webkit-transition: none;
    transition: none
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:active,
body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:focus,
body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:hover {
    color: $menu-item-hover;
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link i {
    font-size: 1.125rem;
    margin-right: 20px;
    margin-left: 6px
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link span {
    display: none;
    padding-left: 10px
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover .side-nav-link {
    position: relative;
    width: 260px;
    color: $menu-item-active;
    background: $menu-item-bg;
    &:after{
        background: $menu-item-active;
    }
    i {
        color: $menu-item-active;
    }
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover .side-nav-link span {
    display: inline
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>ul {
    display: block!important;
    left: 70px;
    position: absolute;
    width: 190px;
    height: auto!important;
    padding: 5px 0;
    z-index: 9999;
    background: $menu-item-bg;
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>ul a {
    padding: 8px 20px;
    position: relative;
    width: 190px
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>ul a:hover {
    color: $menu-item-hover;
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapse,
body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapsing {
    display: block!important;
    -webkit-transition: none!important;
    transition: none!important
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapse>ul,
body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapsing>ul {
    display: block!important;
    left: 70px;
    position: absolute;
    width: 190px;
    background: $menu-item-bg;
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapse>ul a,
body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapsing>ul a {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 8px 20px;
    position: relative;
    width: 190px;
    z-index: 6
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapse>ul a:hover,
body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapsing>ul a:hover {
    color: $menu-item-hover;
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapse>ul li:hover>.collapse,
body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapsing>ul li:hover>.collapse {
    display: block!important;
    height: auto!important;
    -webkit-transition: none!important;
    transition: none!important
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapse>ul li:hover>.collapse>ul,
body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover>.collapsing>ul li:hover>.collapse>ul {
    display: block;
    left: 190px;
    top: 0;
    position: absolute;
    width: 190px
}

body[data-leftbar-compact-mode=condensed] .logo span.logo-lg {
    display: none
}

body[data-leftbar-compact-mode=condensed] .logo span.logo-sm {
    display: block;
    line-height: 70px;
    color: #727cf5
}

.theme-icon-active {
    font-size: 1rem;
}

@media (max-width: 767.98px) {
    body {
        overflow-x:hidden
    }

    .left-menu {
        -webkit-box-shadow: var(--ct-box-shadow);
        box-shadow: var(--ct-box-shadow);
        display: none;
        z-index: 10!important
    }

    .sidebar-enable .left-menu {
        display: block
    }

    .navbar-nav.navbar-right {
        float: right
    }

    .content-page {
        margin-left: 0!important;
        padding: 65px 10px 65px
    }

    body[data-leftbar-compact-mode=condensed] .left-menu {
        margin-left: 0
    }

    .logo span.logo-lg {
        display: block
    }

    .logo span.logo-sm {
        display: none
    }
}

.button-menu-mobile {
    border: 0;
    padding: 0;
    background: 0;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 1.5rem;
}

body[data-leftbar-compact-mode=condensed] .button-menu-mobile {
    transform: rotate(180deg)
}

