@keyframes passing-through {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }

    30%,
    70% {
        opacity: 1;
        transform: translateY(0px);
    }

    100% {
        opacity: 0;
        transform: translateY(-40px);
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }

    30% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.1);
    }

    20% {
        transform: scale(1);
    }
}

.dropzone,
.dropzone * {
    box-sizing: border-box;
}

.dropzone {
    min-height: 150px;
    background-color: var(--bs-tertiary-bg);
    border-radius: 6px;
    padding: 20px 20px;
}

.dropzone.dz-clickable {
    cursor: pointer;
}

.dropzone.dz-clickable * {
    cursor: default;
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
    cursor: pointer;
}

.dropzone.dz-started .dz-message {
    display: none;
}

.dropzone.dz-drag-hover {
    border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
    opacity: .5;
}

.dropzone .dz-message {
    text-align: center;
    margin: 3em 0;
}

.dropzone .dz-message .dz-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px;
}

.dropzone .dz-preview:hover {
    z-index: 1000;
}

.dropzone .dz-preview:hover .dz-details {
    opacity: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
    border-radius: 20px;
    background: #999;
    background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone .dz-preview.dz-file-preview .dz-details {
    opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
    background: var(--bs-tertiary-bg);
}

.dropzone .dz-preview.dz-image-preview .dz-details {
    transition: opacity .2s linear;
}

.dropzone .dz-preview .dz-remove {
    font-size: 14px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: none;
}

.dropzone .dz-preview .dz-remove:hover {
    text-decoration: underline;
}

.dropzone .dz-preview:hover .dz-details {
    opacity: 1;
}

.dropzone .dz-preview .dz-details {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
    padding: 2em 1em;
    text-align: center;
    color: rgba(0, 0, 0, .9);
    line-height: 150%;
}

.dropzone .dz-preview .dz-details .dz-size {
    margin-bottom: 1em;
    font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
    white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
    border: 1px solid rgba(200, 200, 200, .8);
    background-color: rgba(255, 255, 255, .8);
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: 1px solid transparent;
}

.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
    background-color: rgba(255, 255, 255, .4);
    padding: 0 .4em;
    border-radius: 3px;
}

.dropzone .dz-preview:hover .dz-image img {
    transform: scale(1.05, 1.05);
    filter: blur(8px);
}

.dropzone .dz-preview .dz-image {
    border-radius: 20px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    display: block;
    z-index: 10;
}

.dropzone .dz-preview .dz-image img {
    display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
    animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 500;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -27px;
    background: rgba(0, 0, 0, .8);
    border-radius: 50%;
}

.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
    display: block;
    width: 54px;
    height: 54px;
    fill: #fff;
}

.dropzone .dz-preview.dz-processing .dz-progress {
    opacity: 1;
    transition: all .2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
    opacity: 0;
    transition: opacity .4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
    animation: pulse 6s ease infinite;
}

.dropzone .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 20px;
    top: 50%;
    margin-top: -10px;
    left: 15%;
    right: 15%;
    border: 3px solid rgba(0, 0, 0, .8);
    background: rgba(0, 0, 0, .8);
    border-radius: 10px;
    overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
    background: var(--bs-tertiary-bg);
    display: block;
    position: relative;
    height: 100%;
    width: 0;
    transition: width 300ms ease-in-out;
    border-radius: 17px;
}

.dropzone .dz-preview.dz-error .dz-error-message {
    display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
    opacity: 1;
    pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: block;
    display: none;
    opacity: 0;
    transition: opacity .3s ease;
    border-radius: 8px;
    font-size: 13px;
    top: 130px;
    left: -10px;
    width: 140px;
    background: #b10606;
    padding: .5em 1em;
    color: #fff;
}

.dropzone .dz-preview .dz-error-message:after {
    content: "";
    position: absolute;
    top: -6px;
    left: 64px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #b10606;
}

.media, .agent-form {
    .object {
        position: relative;
        cursor: move;
        .thumb {
            position: relative;
            width: 100%;
            aspect-ratio: 1;
            background-color: var(--bs-tertiary-bg);
            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover
            }
            .download,
            .delete {
                position: absolute;
                display: block;
                top: 5px;
                width: 34px;
                height: 34px;
                color: $white;
                font-size: 16px;
                text-align: center;
                line-height: 34px;
                border-radius: 50%;
                background: $black;
                opacity: 0.6;
                &:hover {
                    opacity: 0.9;
                }
            }
            .download {
                left: 5px;
            }
            .delete {
                right: 5px;
            }
        }
    }
}